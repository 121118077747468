import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';

const months = [];
const currentMonth = new Date().getMonth(); // Get current month index (0-11)

for (let i = 11; i >= 0; i--) {
  const monthIndex = (currentMonth - i + 12) % 12; // Calculate month index
  const monthName = new Date(0, monthIndex).toLocaleString('en-US', {
    month: 'short',
  });
  months.push(monthName);
}

const columns = [
  {
    label: 'First Name',
    field: 'first_name',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'Last Name',
    field: 'last_name',
    textAlign: 'left',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'E-mail',
    field: 'email',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Activated',
    field: 'activated_account',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '5%',
  },
  ...months.map((month, index) => ({
    label: month,
    field: `month${index}`,
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '2%',
  })),
];

const renderRowFn = (user) => {
  return {
    first_name: user?.first_name,
    last_name: user?.last_name,
    full_name: user?.full_name,
    email: user?.email,
    activated_account: (
      <div
        className={`flex justify-around items-center ${
          user?.activate_account ? 'p-2 text-green' : 'pt-2 pr-2 pl-2 text-red'
        } rounded-lg`}
      >
        {user?.activate_account ? '✔' : '✘'}
      </div>
    ),
    month0: user?.month0,
    month1: user?.month1,
    month2: user?.month2,
    month3: user?.month3,
    month4: user?.month4,
    month5: user?.month5,
    month6: user?.month6,
    month7: user?.month7,
    month8: user?.month8,
    month9: user?.month9,
    month10: user?.month10,
    month11: user?.month11,
  };
};

const AlexMonthlyUsage = ({ searchQuery, filter }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch } = useQuery(
    [
      'getReportAlexMonthlyUsage',
      { page: currentPage, search: searchQuery, filter },
    ],
    adminAPI.getReportAlexMonthlyUsage
  );

  useEffect(() => {
    if (
      data?.pagination?.current_page &&
      currentPage !== data?.pagination?.current_page
    ) {
      setCurrentPage(data.pagination.current_page);
    }
  }, [data?.pagination?.current_page, currentPage]);

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.reports && (
        <Table
          data={data?.reports}
          columns={columns}
          summary={data?.summary}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default AlexMonthlyUsage;
