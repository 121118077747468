/* eslint-disable */
import React from 'react';
import Introduction from '../components/Introduction';
import BookSummaries from '../components/BookSummaries';
import FieldNoteEventSection from '../components/FieldNoteEventSection';
import HelpToHabitCallout from '../components/HelpToHabitCallout';
import StayInTheKnow from '../components/StayInTheKnow';
import useAuthRedirect from '../hooks/useAuthRedirect';
import useAuth from '../context/AuthContext';
import GetAccess from './GetAccess';
import WelcometoAlexModal from '../components/WelcomeToAlexModal';

const Home = () => {
  const { isLoading, isLoggedIn, isTenant, tenant } = useAuth();

  if (isLoading) {
    return null;
  }

  return (
    <>
      {isLoggedIn ? (
        <>
          <Introduction />
          <HelpToHabitCallout />
          {(!isTenant || tenant?.has_field_notes || tenant?.has_events) && (
            <FieldNoteEventSection />
          )}
          {(!isTenant || tenant?.has_study_groups || tenant?.has_al_direct) && (
            <StayInTheKnow />
          )}
          {(!isTenant || tenant?.has_book_summaries) && <BookSummaries />}

          {/* <WelcometoAlexModal /> */}
        </>
      ) : (
        <GetAccess />
      )}
    </>
  );
};

export default Home;
